<template>
  <v-container>
    <!-- Header -->
    <!--
        <v-row class="d-flex ma-2">
            <v-icon large left color="blue" class="no-print">mdi-printer</v-icon>
            <div class="text-h5 pa-4 blue--text darken-2 no-print">พิมพ์ใบคุมคลัง (3 รายการ)</div>
        </v-row>
        <v-divider class="pa-2 no-print"/>
        -->
    <v-card
      elevation="0"
      style="
        width: 100%;
        height: 100%;
        padding: 1.54cm;
        margin: 0cm;
        page-break-before: always;
      "
    >
      <!-- Empty page is needed to quickly debug print page display -->
    </v-card>
    <template v-for="item in items">
      <v-card
        elevation="0"
        style="
          width: 100%;
          height: 100%;
          padding: 1.54cm;
          margin: 0cm;
          page-break-before: always;
        "
        :key="item.genericCode"
      >
        <!-- Print Date -->
        <!--
                <div style="border-style: solid; border-width: 1px; position: absolute; left:0.25in; top:0.25in" class="pa-2">
                    <div></div>
                </div>
                -->
        <div
          style="
            position: absolute;
            width: 1in;
            border-style: dashed;
            border-width: medium;
            right: 0.25in;
            top: 0.25in;
          "
          class="pa-2 text-h1"
        >
          <div class="text-center">
            {{ item.genericName.toUpperCase().charAt(0) }}
          </div>
        </div>
        <div class="d-flex flex-column align-center justify-center">
          <div class="title">ใบคุมคลัง (Stock Card)</div>
          <div>{{ hosp_name }}</div>
          <div class="title font-weight-bold">
            {{ item.genericName }} {{ item.dosage }}
          </div>
          <div>หน่วยนับ : {{ item.unit }}</div>
        </div>
        <div style="width: 100%; padding-top: 0.2in">
          <table class="tableStyle" style="width: 100%">
            <thead>
              <tr>
                <th class="tableStyle" rowspan="2">วันที่</th>
                <th class="tableStyle" rowspan="2">รับจาก/จ่ายให้</th>
                <th class="tableStyle" rowspan="2">เลขที่ ASN / ใบเบิก</th>
                <th class="tableStyle" rowspan="2">รายการ</th>
                <th class="tableStyle" rowspan="2">ล็อต</th>
                <th class="tableStyle" rowspan="2">วันหมดอายุ</th>
                <th class="tableStyle" colspan="3">จำนวน</th>
                <th class="tableStyle" rowspan="2">ผู้ตรวจทาน</th>
                <th class="tableStyle" rowspan="2">หมายเหตุ</th>
              </tr>
              <tr>
                <th class="tableStyle">รับ</th>
                <th class="tableStyle">เบิก</th>
                <th class="tableStyle">คงเหลือ</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="tx in item.transactions">
                <tr :key="tx.id">
                  <td class="tableStyle pa-1 text-center">
                    {{ new Date(tx.timestamp).toLocaleString("th-TH") }}
                  </td>
                  <td class="tableStyle pa-1 text-center">{{ tx.name }}</td>
                  <td class="tableStyle pa-1 text-center">{{ tx.docNo }}</td>
                  <td class="tableStyle pa-1 text-center">{{ tx.tpuName }}</td>
                  <td class="tableStyle pa-1 text-center">{{ tx.lotNo }}</td>
                  <td class="tableStyle pa-1 text-center">{{ tx.expired }}</td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.quantity.recieve }}
                  </td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.quantity.withdraw }}
                  </td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.quantity.total }}
                  </td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                </tr>
              </template>
              <template v-for="index in calculateFreeTx(item.transactions)">
                <tr style="height: 0.3in" :key="item.genericCode + '/' + index">
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </v-card>
    </template>
  </v-container>
</template>
<script>
import axios from "axios";
// const url = "http://localhost:8910/api";
// const url = "http://anantasolutions.co/flagship/api"
const url = "http://164.115.22.214/api";
export default {
  data: () => {
    return {
      items: [
        {
          genericCode: 1,
          genericName: "Amoxyciliin",
          dosage: "500mg",
          unit: `1*500's กล่อง`,
          transactions: [
            {
              id: 1,
              timestamp: "22/9/56",
              name: "ยอดคงเหลือยกมา",
              docNo: "-",
              quantity: {
                recieve: 50,
                withdraw: 0,
                total: 50,
              },
              expired: "22/9/56",
              tpuName: "Xylomox-500",
              lotNo: "7458",
            },
            {
              id: 1,
              timestamp: "22/9/56",
              name: "ยอดคงเหลือยกมา",
              docNo: "-",
              quantity: {
                recieve: 50,
                withdraw: 0,
                total: 50,
              },
              expired: "22/9/56",
              tpuName: "Xylomox-500",
              lotNo: "7458",
            },
          ],
        },
        {
          genericCode: 2,
          genericName: "Paracetamol",
          dosage: "500mg",
          unit: `1*500's กล่อง`,
          transactions: [
            {
              id: 1,
              timestamp: "22/9/56",
              name: "ยอดคงเหลือยกมา",
              docNo: "-",
              quantity: {
                recieve: 50,
                withdraw: 0,
                total: 50,
              },
              expired: "22/9/56",
              tpuName: "Xylomox-500",
              lotNo: "7458",
            },
            {
              id: 1,
              timestamp: "22/9/56",
              name: "ยอดคงเหลือยกมา",
              docNo: "-",
              quantity: {
                recieve: 50,
                withdraw: 0,
                total: 50,
              },
              expired: "22/9/56",
              tpuName: "Xylomox-500",
              lotNo: "7458",
            },
          ],
        },
      ],
      hosp_name: ""
    };
  },
  methods: {
    applyRouterLink(name) {
      this.$router.push({ name });
    },
    calculateFreeTx(transactions) {
      return  transactions.length;
    },
    initialize() {
      // init here
      this.hosp_name = this.$cookies.get("user").hosp_name
    },
    getTransactions() {
      this.items = [];
      var preitems;
      // console.log('getTransactions')
      axios
        .post(url + "/pcoc/getRB301", {
          hosp_code: this.$cookies.get("user").hosp_code,
          selected: this.$route.params.selected,
        })
        .then((res) => {
          preitems = res.data;
          // console.log(preitems)
          preitems.forEach((element) => {
            var item = {
              genericCode: element.genericCode,
              genericName: element.genericName,
              type: element.type,
              dosage: element.dosage,
              unit: element.unit,
              transactions: [],
            };
            var subTotal = 0
            var netPrice = 0
            element.logs.forEach((el) => {
               // calculate subTotal and netPrice
              subTotal += el.quantity.receive
              subTotal -= el.quantity.withdraw
              netPrice += (el.quantity.receive - el.quantity.withdraw) * el.unitPrice
              var log = {
                id: el.id,
                timestamp: el.timestamp,
                name: el.name,
                docNo: "-",
                unitPrice: el.unitPrice,
                quantity: {
                  recieve: el.quantity.receive,
                  withdraw: el.quantity.withdraw,
                  total: subTotal,
                },
                totalPrice: netPrice,
                lotNo: el.lot_no,
                tpuName: el.trade_id,
                expired: el.expiry_date,
              };
              item.transactions.push(log);
            });
            if (item.transactions.length > 0) {
              this.items.push(item);
            }
          });
        });
    },
  },
  computed: {
    getTotalItems() {
      return this.items.length;
    },
  },
  mounted() {
    this.initialize();
    this.getTransactions();
  },
};
</script>